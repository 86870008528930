// import { Fragment, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import "./MtsResultPageContent.scss";
// import images from "../../../../utilities/images/images";
// import uiActions from "../../../../redux/ui/action";
// import { useDispatch } from "react-redux";

// const MtsResultPageContent = () => {
//   const history = useHistory();
//   //   const [isLoading, setIsLoading] = useState(true);

//   const dispatch = useDispatch();

//   // Function to show loader
//   //   const startLoading = () => {
//   //     setIsLoading(true);
//   //   };

//   // Function to hide loader when iframe loads
//   //   const stopLoading = () => {
//   //     setIsLoading(false);
//   //   };

//   const handleRedirect = () => {
//     history.push("/user_dashboard");
//   };

//   useEffect(() => {
//     startLoading();
//   }, []);

//   const startLoading = () => {
//     dispatch(uiActions.startLoading());
//   };

//   const finishLoading = () => {
//     dispatch(uiActions.finishLoading());
//   };

//   return (
//     <Fragment>
//       <div className="userEditProfileContentComponent_wrapper">
//         <div className="container">
//           <div className="userEditProfileContentComponent_inner_wrapper">
//             <div className="back_btn">
//               <button onClick={handleRedirect}>
//                 <img src={images.login_2} alt="Back" />
//               </button>
//             </div>

//             {/* Loader */}
//             {/* {isLoading && (
//               <div className="loader">
//                 {startLoading()}
//               </div>
//             )} */}

//             {/* Embed iframe with onLoad event */}
//             <div
//               className="iframe_container"
//               //   style={{ display: isLoading ? "none" : "block" }}
//             >
//               <iframe
//                 src="https://mtseresult.motion.ac.in/login.php"
//                 title="MTS Result"
//                 style={{
//                   width: "100%",
//                   height: "80vh",
//                   border: "none",
//                   marginTop: "20px",
//                 }}
//                 allowFullScreen
//                 onLoad={finishLoading()} // Stop loader when iframe loads
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default MtsResultPageContent;

import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./MtsResultPageContent.scss";
import images from "../../../../utilities/images/images";

const MtsResultPageContent = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  // Function to show loader
  const startLoading = () => {
    setIsLoading(true);
  };

  // Function to hide loader when iframe loads
  const stopLoading = () => {
    setIsLoading(false);
  };

  const handleRedirect = () => {
    history.push("/user_dashboard");
  };

  useEffect(() => {
    // Start loading when component mounts
    startLoading();
  }, []);

  return (
    <Fragment>
      <div className="userEditProfileContentComponent_wrapper_mts">
        <div className="container">
          <div className="userEditProfileContentComponent_inner_wrapper_mts">
            {/* <div className="back_btn">
              <button onClick={handleRedirect}>
                <img src={images.login_2} alt="Back" />
              </button>
            </div> */}

            {isLoading && (
              <p
                style={{
                  textAlign: "center",
                  //   marginTop: "50px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  //   alignItems: "center",
                }}
              >
                Loading...
              </p>
            )}

            <div
              className="iframe_container"
              style={{ display: isLoading ? "none" : "block" }}
            >
              <iframe
                src="https://mtseresult.motion.ac.in/login.php"
                title="MTS Result"
                style={{
                  width: "100%",
                  height: "80vh",
                  border: "none",
                  marginTop: "20px",
                }}
                allowFullScreen
                onLoad={stopLoading} // Stop loader when iframe loads
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MtsResultPageContent;
