import { Fragment, useEffect, useState } from "react";
import "./schedule_class.scss";
// Import react-circular-progressbar module and styles
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import images from "../../../../../../utilities/images/images";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import {
  isEmpty,
  sliceString,
  splitString,
} from "../../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";
import {
  url_14,
  url_15,
  url_15_1,
  url_55,
  url_99,
  url_100,
  url_8,
} from "../../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../redux/user_activity/action";

import * as base64JS from "js-base64";
import * as rdd from "react-device-detect";
import { setLiveVideoStreamDetails } from "../../../../../../redux/live_video_streaming/LiveVideoStreamingSlice";

const ScheduleClass = (props) => {
  let {
    history,
    userPreference,
    startLoading,
    finishLoading,
    setVideoData,
    setNotesData,
    setLiveStreamData,
    token,
    tokenType,
    setLiveVideoStreamDetailsAction,
    isPaid,
  } = props;
  let {
    targetDetails,
    courseDetails,
    classDetails,
    batchDetails,
  } = userPreference;

  const [userTestProgressDetails, setUserTestProgressDetails] = useState({});

  const [state, setState] = useState({
    selectedSchedule: {
      date: "",
      day: "",
    },
    scheduleTimeTable: {},
    scheduleData: {
      videos: [],
      notes: [],
      liveClasses: [],
      offline: [],
    },
    // isPaid: null,
  });

  useEffect(() => {
    getScheduleTimeTable();
    getMyProgressDetails();
    // getUserData();
  }, []);

  useEffect(() => {
    if (state.selectedSchedule.date) {
      getScheduleContent();
    }
  }, [state.selectedSchedule.date]);

  // const getUserData = async () => {
  //   try {
  //     const response = await axios.get(url_8);
  //     console.log('calling in sechule class')
  //     if (response.data.status === 200) {
  //       setState((prevState) => ({
  //         ...prevState,
  //         isPaid: response.data.data.is_paid,
  //       }));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getMyProgressDetails = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const response = await axios.post(url_99, headers);
    if (response.data.status === 200) {
      setUserTestProgressDetails({ ...response.data.data });
    }
  };

  const getScheduleTimeTable = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
    };

    try {
      let response = await axios.get(
        `${url_14}?course_id=${data.course_id}&batch_id=${data.batch_id}`
      );
      if (response.data.status === 200) {
        if (response.data.data.ScheduleWeek.length) {
          let returnedResponse = getTodayDateAndDay();
          setState((prevState) => ({
            ...prevState,
            scheduleTimeTable: response.data.data,
            selectedSchedule: {
              ...prevState.selectedSchedule,
              // date: response.data.data.ScheduleWeek[0].date,
              // day: response.data.data.ScheduleWeek[0].day,
              date: returnedResponse.date,
              day: returnedResponse.day,
            },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTodayDateAndDay = () => {
    let d = new Date();
    let date, month, year, day;
    let dayArr = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    date = d.getDate();
    month = d.getMonth();
    year = d.getFullYear();
    day = d.getDay();

    if (date < 10) {
      date = `0${date}`;
    }

    // if (month < 10) {
    //   month = `0${month + 1}`;
    // }
    if (month < 9) {
      month = `0${month + 1}`;
    } else {
      month = `${month + 1}`;
    }

    let data = {
      date: `${year}-${month}-${date}`,
      day: dayArr[day],
    };
    return data;
  };

  const handleChangeScheduleDate = (event, date, day) => {
    setState((prevState) => ({
      ...prevState,
      selectedSchedule: { ...prevState.selectedSchedule, date: date, day: day },
    }));
  };

  const getScheduleContent = async () => {
    let selectedSchedule = { ...state.selectedSchedule };
    let data = {
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      day: selectedSchedule.day,
      date: selectedSchedule.date,
    };
    startLoading();
    try {
      const response = await axios.post(url_15_1, data);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          scheduleData: {
            ...prevState.scheduleData,
            videos: response.data.data.video,
            notes: response.data.data.notes,
            liveClasses: response.data.data.zoomclass,
            offline: response.data.data.offline,
          },
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleStoreVideoData = (videoData) => {
    if (!videoData.is_paid) {
      videoData.redirectedFrom = "schedule_class";
      setVideoData(videoData);
      history.push("/videos");
    } else {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
    }
  };

  const handleStoreNotesData = (notesData) => {
    if (notesData.is_paid) {
      notesData.redirectedFrom = "schedule_class";
      setNotesData(notesData);
      history.push("/notes");
    } else {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
    }
  };

  const setLiveClassRecord = async (element) => {
    if (isPaid === 0) {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
      return;
    }

    let zoomId = decrypt_functionality(element.zoom_id);

    // let zoomId = decrypt_functionality(element.zoom_id);

    let data = {
      device_id: rdd.fullBrowserVersion,
      device_type: rdd.browserName,
      device_name: rdd.osName,
      zoom_id: zoomId,
      type: "Web",
    };
    startLoading();
    try {
      const response = await axios.post(url_55, data);
      if (response.data.status === 200) {
        finishLoading();

        if (element.stream_service === "1") {
          handleStoreLiveStreamData(element);
        } else if (element.stream_service === "2") {
          setLiveVideoStreamDetailsAction({
            zoomId,
            isLive: true,
            live_class_id: element.id,
          });

          history.push({
            pathname: "/streaming",
            state: { zoomId: zoomId, isLive: true, live_class_id: element.id },
          });
        } else {
          alert("There was an error, Please try again later");
        }
      } else {
        finishLoading();
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const decrypt_functionality = (val) => {
    const CryptoJS = require("crypto-js");

    let encrypted = val;
    let key = "0CiuKpLCZbX7gBCyYTka4USxTnh7ssXKk8bJ6Y3TXPc=";

    encrypted = atob(encrypted);

    encrypted = JSON.parse(encrypted);

    // console.log('Laravel encryption result', encrypted);

    // IV is base64 encoded in Laravel, expected as word array in cryptojs
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);

    // Value (chipher text) is also base64 encoded in Laravel, same in cryptojs
    const value = encrypted.value;

    // Key is base64 encoded in Laravel, word array expected in cryptojs
    key = CryptoJS.enc.Base64.parse(key);

    // Decrypt the value, providing the IV.
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
    });

    // CryptoJS returns a word array which can be
    // converted to string like this
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    //console.log(decrypted); // Voilà! Prints "Hello world!"

    return decrypted.split("*")[1];
  };

  const handleStoreLiveStreamData = (liveStreamData) => {
    // if (liveStreamData.is_paid) {
    liveStreamData.redirectedFrom = "schedule_class";
    setLiveStreamData(liveStreamData);
    history.push("/zoom");
    // } else {
    //   alert("You haven't purchased a plan. Please purchase it from store.");
    //   history.push("/store");
    // }
  };

  const renderNoDataPlaceholder = (type) => {
    switch (type) {
      case "videos":
        return (
          <div
            className="no_data_found_wrapper"
            style={{ textAlign: "center" }}
          >
            {/* <div className="text_content_wrapper">
              <p>Videos Section</p>
            </div> */}
            {/* <img src={images.no_data} /> */}
          </div>
        );
      // case "notes":
      //   return (
      //     <div
      //       className="no_data_found_wrapper"
      //       style={{ textAlign: "center" }}
      //     >
      //       <div className="text_content_wrapper">
      //         <p>Notes Section</p>
      //       </div>
      //       <img src={images.no_data} />
      //     </div>
      //   );
      // case "live_class":
      //   return (
      //     <div
      //       className="no_data_found_wrapper"
      //       style={{ textAlign: "center" }}
      //     >
      //       <div className="text_content_wrapper">
      //         <p>Live Class Section</p>
      //       </div>
      //       <img src={images.no_data} />
      //     </div>
      //   );
      default:
        return null;
    }
  };
  function getLabel({
    current_time: currentTime,
    start_time: startTime,
    meetdate,
    end_time: endTime,
    stream_service,
    status_stream,
  }) {
    if (stream_service === "1") {
      currentTime = getMomentFromString(currentTime);
      startTime = getMomentFromString(startTime, meetdate);
      endTime = getMomentFromString(endTime, meetdate);
      let label = "";

      if (currentTime.isBefore(startTime)) label = "Upcoming";
      else if (currentTime.isBetween(startTime, endTime)) label = "Live";
      else if (currentTime.isAfter(endTime)) label = "Ended";
      return label;
    } else if (stream_service === "2") {
      let label = "";

      if (status_stream === "live") {
        label = "Live";
      } else if (status_stream === "ended") {
        label = "Ended";
      } else if (status_stream === "recorded") {
        label = "Recorded";
      } else {
        label = "Upcoming";
      }
      return label;
    }
  }
  //   function getLabel(currentTime, startTime, endTime, meetdate) {
  //     currentTime = getMomentFromString(currentTime);
  //     startTime = getMomentFromString(startTime, meetdate);
  //     endTime = getMomentFromString(endTime, meetdate)
  //     let label = ""
  //     if (currentTime.isBefore(startTime)) label = "upcomming";
  //     else if (currentTime.isBetween(startTime, endTime)) label = "live";
  //     else if (currentTime.isAfter(endTime)) label = "end"
  //     return label
  // }

  function getMomentFromString(timeString, meetdate) {
    const date = meetdate ? moment(meetdate, "YYYY-MM-DD") : moment();
    const [hour, minute, second = "0"] = timeString.split(":");
    return date.set({ hour, minute, second });
  }
  // function getMomentFromString(timeString){
  //     const date = moment();
  //     const [ hour, minute, second = "0"] = timeString.split(":")
  //     return date.set({hour, minute, second})
  //   }
  const percentage = 60;
  return (
    <Fragment>
      <div className="scheduleClass_wrapper">
        <div className="scheduleClass_inner_wrapper">
          <div className="scheduleClass_left">
            <h4>My Schedule</h4>
            <section
              className="sec_2_wrapper"
              style={{ margin: "10px 0px 20px" }}
            >
              <div className="date_time_collection">
                {!isEmpty(state.scheduleTimeTable)
                  ? state.scheduleTimeTable.ScheduleWeek.length
                    ? state.scheduleTimeTable.ScheduleWeek.map(
                        (element, index) => {
                          return (
                            <div key={index} className="single_select_date">
                              <input
                                type="radio"
                                id={element.date}
                                name="dateTime"
                                value={element.date}
                                onChange={(event) =>
                                  handleChangeScheduleDate(
                                    event,
                                    element.date,
                                    element.day
                                  )
                                }
                                checked={
                                  element.date === state.selectedSchedule.date
                                }
                              />

                              <label htmlFor={element.date}>
                                <div className="text_content_wrapper_1">
                                  <p className="text_content_1">
                                    {sliceString(element.day, 0, 3)}
                                  </p>
                                </div>
                                <div className="text_content_wrapper_2">
                                  <p className="text_content_2">
                                    {splitString(element.date, "end")}
                                  </p>
                                </div>
                              </label>
                            </div>
                          );
                        }
                      )
                    : null
                  : null}
              </div>
            </section>
            <div className="sec_3_wrapper">
              <div className="classes_collection">
                {/* //////////////////videos//////////////////////////////////////////// */}
                {state.scheduleData.videos.length
                  ? state.scheduleData.videos.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_class_wrapper"
                          onClick={() => handleStoreVideoData(element)}
                        >
                          <div className="left_side_container_wrapper">
                            <div className="image_wrapper">
                              <img src={element.video_thumnail} />
                            </div>
                          </div>
                          <div className="right_side_container_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.notes_title}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">{`by ${element.by_upload}`}</p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">
                                {element.subject}
                              </p>
                            </div>
                            <div className="sub_content_wrapper">
                              <div className="left_side_container_wrapper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">
                                    <img src={images.user_dashboard_3} />
                                    {`${element.start_time} - ${element.end_time}`}
                                  </p>
                                </div>
                              </div>
                              <div className="right_side_container_wrapper">
                                <span className="subject_name">Video</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : renderNoDataPlaceholder("videos")}
                {/* ////////////////////////////notes/////////////////////////////// */}
                {state.scheduleData.notes.length
                  ? state.scheduleData.notes.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_class_wrapper"
                          onClick={() => handleStoreNotesData(element)}
                        >
                          <div className="left_side_container_wrapper">
                            <div className="image_wrapper">
                              <img src={element.video_thumnail} />
                            </div>
                          </div>
                          <div className="right_side_container_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.notes_title}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">{`by ${element.by_upload}`}</p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">
                                {element.subject}
                              </p>
                            </div>
                            <div className="sub_content_wrapper">
                              <div className="left_side_container_wrapper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">
                                    <img src={images.user_dashboard_3} />
                                    {`${element.start_time} - ${element.end_time}`}
                                  </p>
                                </div>
                              </div>
                              <div className="right_side_container_wrapper">
                                <span className="subject_name">Notes</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : // renderNoDataPlaceholder("notes")}
                    null}
                {/* ///////////////////////////live Classess/////////////////////////// */}

                {state.scheduleData.liveClasses.length
                  ? state.scheduleData.liveClasses.map((element, index) => {
                      return (
                        <div
                          key={"live_class_" + index}
                          className="single_class_wrapper"
                          onClick={() => setLiveClassRecord(element)}
                        >
                          <div className="left_side_container_wrapper">
                            <div className="image_wrapper">
                              <img src={element.faculty_image} />
                            </div>
                          </div>
                          <div className="right_side_container_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.description}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2 batchNm">
                                {element.subject}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">{`by ${element.by_upload}`}</p>
                            </div>
                            <div className="sub_content_wrapper">
                              <div className="left_side_container_wrapper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">
                                    <span>Join Live Class at:-</span>
                                    {`${moment(
                                      element.start_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")} - ${moment(
                                      element.end_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}`}
                                  </p>
                                  <span className={`${getLabel(element)}`}>
                                    {getLabel(element)}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="right_side_container_wrapper">
                                <span className="subject_name">
                                  {element.current_time < element.end_time &&
                                  element.current_time > element.start_time
                                    ? "Running"
                                    : element.current_time > element.end_time
                                    ? "Ended"
                                    : element.current_time < element.start_time
                                    ? "Upcoming"
                                    : "Problem"}
                                </span>
                              </div> */}
                            </div>
                            <div className="arrow_dv">
                              <img src={images.right_Ar} alt="arrow" />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : // renderNoDataPlaceholder("live_class")
                    null}
                {/* ///////////////////////////Offline Classess/////////////////////////// */}

                {state.scheduleData.offline.length
                  ? state.scheduleData.offline.map((element, index) => {
                      return (
                        <div
                          key={"live_class_" + index}
                          className="single_class_wrapper"
                          // onClick={() => setLiveClassRecord(element)}
                        >
                          <div className="left_side_container_wrapper">
                            <div className="image_wrapper">
                              <img src={element.faculty_image} />
                            </div>
                          </div>
                          <div className="right_side_container_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {`Batch - ${element.batch_name} (${element.subject_name})`}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2 batchNm">
                                {`Topic - ${element.topic}`}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">{`by ${element.faculty_name} at ${element.venue}`}</p>
                            </div>
                            <div className="sub_content_wrapper">
                              <div className="left_side_container_wrapper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">
                                    <span>Offline Class,</span>
                                    {`${moment(
                                      element.start_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")} - ${moment(
                                      element.end_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}`}
                                  </p>
                                  {element.date >
                                  moment(new Date()).format("YYYY-MM-DD") ? (
                                    <span className="Upcoming">Upcoming</span>
                                  ) : element.date <
                                    moment(new Date()).format("YYYY-MM-DD") ? (
                                    <span className="Ended">Ended</span>
                                  ) : element.date ==
                                      moment(new Date()).format("YYYY-MM-DD") &&
                                    moment(new Date()).format("hh:mm A") <
                                      element.end_time &&
                                    moment(new Date()).format("hh:mm A") >
                                      element.start_time ? (
                                    <span className="Live">Running</span>
                                  ) : moment(new Date()).format("hh:mm A") <
                                    element.start_time ? (
                                    <span className="Upcoming">Upcoming</span>
                                  ) : (
                                    <span className="Ended">Ended</span>
                                  )}
                                </div>
                              </div>
                              {/* <div className="right_side_container_wrapper">
                                <span className="subject_name">
                                  {element.current_time < element.end_time &&
                                  element.current_time > element.start_time
                                    ? "Running"
                                    : element.current_time > element.end_time
                                    ? "Ended"
                                    : element.current_time < element.start_time
                                    ? "Upcoming"
                                    : "Problem"}
                                </span>
                              </div> */}
                              {/* <div className="right_side_container_wrapper">
                                <span className="text_content">
                                  {element.date >
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? <span className="Upcoming">Upcoming</span>
                                    : element.date < moment(new Date()).format("YYYY-MM-DD")
                                    ? <span className="Ended">Ended</span>
                                    : element.date == moment(new Date()).format("YYYY-MM-DD") && moment(new Date()).format("hh:mm A") < element.end_time && moment(new Date()).format("hh:mm A") > element.start_time
                                    ? <span className="Live">Running</span>
                                    : moment(new Date()).format("hh:mm A") < element.start_time
                                    ? <span className="Upcoming">Upcoming</span>
                                    : <span className="Ended">Ended</span>}
                                </span>
                              </div> */}
                            </div>
                            <div className="arrow_dv">
                              {/* <img src={images.right_Ar} alt="arrow" /> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : // renderNoDataPlaceholder("live_class")
                    null}
              </div>
            </div>
          </div>
          <div className="scheduleClass_right">
            <div className="my_progress">
              <h4>My Progress</h4>
              <div className="progress_box_wrapper">
                <div className="progress_box">
                  <div className="progress-circle">
                    <div label="Default">
                      <CircularProgressbar
                        value={
                          userTestProgressDetails.user_total_test
                            ? userTestProgressDetails.user_total_test
                            : null
                        }
                        text={`${
                          userTestProgressDetails.user_total_test
                            ? userTestProgressDetails.user_total_percentage
                            : 0
                        }%`}
                        strokeWidth={6}
                        className="CircularProgressbarr"
                      />
                    </div>
                    <span className="score">Overall Score</span>
                  </div>
                  <div className="prgs_tst_detail">
                    <div className="tst_dtl_count">
                      <span className="tst_count">
                        {userTestProgressDetails
                          ? userTestProgressDetails.user_total_test
                          : null}
                      </span>
                      <span className="tst_name">Test Attempted</span>
                    </div>

                    <div className="tst_dtl_count">
                      <span className="tst_count">
                        {userTestProgressDetails
                          ? userTestProgressDetails.userdatacorrect
                          : null}
                      </span>
                      <span className="tst_name">Correct Answers</span>
                    </div>
                    <div className="tst_dtl_count">
                      <span className="tst_count">
                        {userTestProgressDetails
                          ? userTestProgressDetails.userdataincorrect
                          : null}{" "}
                      </span>
                      <span className="tst_name">Incorrect Answers</span>
                    </div>
                    <div className="tst_dtl_count">
                      <span className="tst_count">
                        {userTestProgressDetails
                          ? userTestProgressDetails.userdataunattempt
                          : null}{" "}
                      </span>
                      <span className="tst_name">Questions Skipped</span>
                    </div>
                    <div className="arrow_dv">
                      <Link
                        to={{
                          pathname: "/user_analytics",
                        }}
                      >
                        <img src={images.m2_rgtArow} alt="arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pyq_cyps_wrapper">
              <h4>PYQ/CPS</h4>
              <div className="pyq_cpsBox">
                <Link
                  className="pyqCpsBox"
                  to={{
                    pathname: "/pyq",
                    state: { tab: "pyq" },
                  }}
                >
                  <span>Previous Year Papers</span>
                  <span>
                    <img src={images.m2_rgtArow} alt="arrow" />
                  </span>
                </Link>
                <Link
                  className="pyqCpsBox"
                  to={{
                    pathname: "/cps",
                    state: { tab: "cps" },
                  }}
                >
                  <span>Conceptual Practice Sheets</span>
                  <span>
                    <img src={images.m2_rgtArow} alt="arrow" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setLiveStreamData: (payload) => {
      dispatch(userActivityActions.setLiveStreamData(payload));
    },

    setLiveVideoStreamDetailsAction: (payload) => {
      dispatch(setLiveVideoStreamDetails(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleClass);
