import { Fragment, useEffect, useState } from "react";
import "./video_feature_content_component.scss";

import images from "../../../../../utilities/images/images";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../custom_utilities/baseURL";
import axios from "axios";
import {
  url_7,
  url_23,
  url_33,
  url_39,
  url_43,
  url_226,
} from "../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";
import userBookmarkActions from "../../../../../redux/user_bookmark/action";
import VideoDrmPlayer from "./VideoDrmPlayer";

const VideoFeatureContentComponent = (props) => {
  let {
    videoData,
    userData,
    history,
    userPreference,
    videoComponentTime,
    videoCurrentTime,
    videoDetailsForTime,
    startLoading,
    finishLoading,
    setVideoData,
    setNotesData,
    setExerciseData,
    setExerciseResultData,
    setVideoComponentTime,
    setVideoDetailsForTime,
    setVideoCurrentTime,
    topicData,
    subjectData,
    watchedVideoData,
    videoLastPaused,
    totalWatchTime,
  } = props;

  const videoIdCrypt = "3301950_0_7858418889558863";

  const [state, setState] = useState({
    videoId: null,
    videoDetails: {},
    optionFeatureType: 1,
    dppCollections: {},
    videosDrmUrl: {},
    flag: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setVideoId();
    setVideoComponentInitialTime();

    // return () => {
    //   // saveUserVideoWatchTime();
    // };
  }, []);

  useEffect(() => {
    if (state.videoId) {
      getVideoDetails();
    }
  }, [state.videoId]);

  useEffect(() => {
    if (!isEmpty(state.videoDetails) && state.videoDetails.last_paused) {
      handleJumpToParticularTime();
    }
  }, [state.videoDetails]);

  const setVideoId = () => {
    let { video_id } = videoData;
    setState((prevState) => ({ ...prevState, videoId: video_id }));
  };

  const setVideoComponentInitialTime = () => {
    let d = new Date();
    setVideoComponentTime(d.getTime());
  };

  const saveUserVideoWatchTime = async () => {
    let d = new Date();
    let finalWatchTime = Math.round((d.getTime() - videoComponentTime) / 1000);

    let videoTimmingDetailsParsedData = JSON.parse(
      sessionStorage.getItem("videoTimmingDetails")
    );

    let data = {
      total_duration: finalWatchTime,
      last_paused: parseInt(videoTimmingDetailsParsedData.currentTime),
      video_id: videoDetailsForTime.video_id,
      subject_id: videoDetailsForTime.subject_id,
    };
    try {
      const response = await axios.post(url_43, data);
      if (response.data.status === 200) {
        // setVideoCurrentTime(0);
        // setVideoComponentTime(0);
        sessionStorage.removeItem("videoTimmingDetails");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVideoDetails = async () => {
    let { videoId } = state;
    try {
      const response = await axios.get(`${url_7}?video_id=${videoId}`);

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          videoDetails: response.data.data.VideoDetail,
        }));

        setVideoDetailsForTime(response.data.data.VideoDetail);
        getDrmUrl(response.data.data.VideoDetail.video_crypt_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDrmUrl = async (videoCryptId) => {
    // try {
    //     setState((prevState) => ({
    //       ...prevState,
    //       videosDrmUrl: response.data.data.link.file_url,
    //       flag: true,
    //     }));
    //     alert(this.videosDrmUrl)
    //   }
    //  catch (error) {
    //   console.log(error);
    // }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userData.user_id}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleJumpToParticularTime = () => {
    let videoDetails = { ...state.videoDetails };
    if (!isEmpty(videoDetails) && videoDetails.last_paused) {
      let frame = document.querySelector("#iframe");
      //////jump to particular time///////////////////////
      frame.contentWindow.postMessage(
        JSON.stringify({ seekTo: videoDetails.last_paused }),
        "*"
      );
    }
  };

  const handleSeekTo = (jumpTime) => {
    let timeSplitArr = jumpTime.split(":");
    let timeInSeconds =
      timeSplitArr[0] * 60 * 60 + timeSplitArr[1] * 60 + timeSplitArr[2];

    let frame = document.querySelector("#iframe");
    //////jump to particular time///////////////////////
    frame.contentWindow.postMessage(
      JSON.stringify({ seekTo: timeInSeconds }),
      "*"
    );
  };

  /////////Render Video Frame////////////////////
  const renderFrame = (data) => {
    switch (data.redirectedFrom) {
      case "schedule_class":
        if (data?.video_crypt_id) {
          return (
            <VideoDrmPlayer
              id_video={data?.video_crypt_id}
              videoDetailsForTime={videoDetailsForTime}
              topicData={topicData}
              subjectData={subjectData}
              data={data}
            />
          );
        } else {
          return (
            <iframe
              id="iframe"
              // src={`https://speed.mogiio.com/embed3/602bbc635edad16d72488ffd`}
              src={data.video_url}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }

      case "activity":
        if (data?.video_crypt_id) {
          return (
            <VideoDrmPlayer
              id_video={data?.video_crypt_id}
              videoDetailsForTime={videoDetailsForTime}
              topicData={topicData}
              subjectData={subjectData}
              data={data}
            />
          );
        } else {
          return (
            <iframe
              id="iframe"
              // src={`https://speed.mogiio.com/embed3/602bbc635edad16d72488ffd`}
              src={data.video_url}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }
      case "free_videos":
        if (data?.video_crypt_id) {
          return (
            <VideoDrmPlayer
              id_video={data?.video_crypt_id}
              videoDetailsForTime={videoDetailsForTime}
              topicData={topicData}
              subjectData={subjectData}
              data={data}
            />
          );
        } else {
          return (
            <iframe
              id="iframe"
              // src={`https://speed.mogiio.com/embed3/602bbc635edad16d72488ffd`}
              src={data.video_url}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }
      case "last_paused_video":
        if (data?.video_crypt_id) {
          return (
            // <video id="iframe" style={{ width: "100%", height: "100%" }}></video>
            <VideoDrmPlayer
              id_video={data?.video_crypt_id}
              videoDetailsForTime={videoDetailsForTime}
              topicData={topicData}
              subjectData={subjectData}
              data={data}
            />
          );
        } else {
          return (
            <iframe
              id="iframe"
              // src={`https://speed.mogiio.com/embed3/602bbc635edad16d72488ffd`}
              src={data.video_url}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }
      default:
        return null;
    }
  };

  ///////////////Render Video Details/////////////////

  const renderVideoDetails = () => {
    return (
      <Fragment>
        <div className="video_details_content_wrapper">
          <div className="text_content_wrapper">
            <p className="text_content">
              {!isEmpty(state.videoDetails)
                ? state.videoDetails.video_title
                : null}
            </p>
          </div>
          <div className="content_wrapper_1">
            <div className="left_side_content_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">
                  {!isEmpty(state.videoDetails)
                    ? state.videoDetails.UploadBy.name
                    : null}
                </p>
              </div>
            </div>
            <div className="right_side_content_wrapper">
              <div className="label_content_wrapper">
                <label>
                  <span>
                    <img src={images.user_activity_2} />
                  </span>
                  <span>
                    {!isEmpty(state.videoDetails)
                      ? state.videoDetails.video_rating
                      : null}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="content_wrapper_2">
            <div className="text_content_wrapper">
              <p className="text_content">Content</p>
            </div>
            <div className="topic_collection">
              {!isEmpty(state.videoDetails)
                ? state.videoDetails.content.length
                  ? state.videoDetails.content.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="sub_content_wrapper"
                          onClick={() => handleSeekTo(element.content_time)}
                        >
                          <div className="left_side_content_wrapper">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                <label className="key_tag">{`Topic ${
                                  index + 1
                                }:`}</label>
                                <label className="value_tag">
                                  {element.content}
                                </label>
                              </p>
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                <label>{element.content_time}</label>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  ////////////Render Other Related Content////////////
  const renderOtherRelatedContent = () => {
    return (
      <Fragment>
        <div className="other_related_content_container">
          <div className="options_collection_wrapper">
            <div className="options_collection">
              <div className="single_select_option">
                <input
                  type="radio"
                  id="notes"
                  name="options_feature"
                  value="notes"
                  onChange={(event) => handleChangeFeatureType(event)}
                  checked={state.optionFeatureType === 1}
                />
                <label htmlFor="notes">NOTES</label>
              </div>
              <div className="single_select_option">
                <input
                  type="radio"
                  id="dpp"
                  name="options_feature"
                  value="dpp"
                  onChange={(event) => handleChangeFeatureType(event)}
                  checked={state.optionFeatureType === 2}
                />
                <label htmlFor="dpp">DPP</label>
              </div>
            </div>
          </div>
          <div className="options_data_view_wrapper">{renderOptions()}</div>
        </div>
      </Fragment>
    );
  };

  const handleChangeFeatureType = (event) => {
    let { value } = event.target;

    switch (value) {
      case "notes":
        setState((prevState) => ({ ...prevState, optionFeatureType: 1 }));
        break;
      case "dpp":
        setState((prevState) => ({ ...prevState, optionFeatureType: 2 }));
        getDppList();
        break;
      default:
        break;
    }
  };

  const renderOptions = () => {
    let { optionFeatureType } = state;
    // 1 for notes
    // 2 for dpp
    switch (optionFeatureType) {
      case 1:
        return renderNotesCollectionView();
      case 2:
        return renderDppCollectionView();
      default:
        return null;
    }
  };

  const renderNotesCollectionView = () => {
    return (
      <Fragment>
        <div className="notesCollectionView_wrapper">
          <div className="notesCollectionView_inner_wrapper">
            <div className="notes_collection_wrapper">
              {!isEmpty(state.videoDetails) ? (
                state.videoDetails.NotesOfVideos.length ? (
                  state.videoDetails.NotesOfVideos.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_notes_wrapper"
                        onClick={() =>
                          handleStoreDataInStorage("notes", element)
                        }
                      >
                        <div className="serial_number_wrapper">
                          <div className="image_wrapper">
                            <img src={images.user_activity_5} />
                          </div>
                        </div>
                        <div className="notes_description_wrapper">
                          <div className="notes_details_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.notes_title}
                              </p>
                            </div>
                            {/* <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  Lorem ipsum dolor is amet.
                                </p>
                              </div> */}
                          </div>
                          <div className="caret_wraper">
                            <div className="image_wrapper">
                              {/* {element.IsSaved ? (
                                  <img src={images.user_activity_4} />
                                ) : (
                                  <img src={images.user_activity_3} />
                                )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <img src={images.no_data} />
                  </div>
                )
              ) : (
                <div
                  className="no_data_found_wrapper"
                  style={{ textAlign: "center" }}
                >
                  <img src={images.no_data} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderDppCollectionView = () => {
    return (
      <Fragment>
        <div className="dppCollectionView_wrapper">
          <div className="dppCollectionView_inner_wrapper">
            <div className="dpp_collection_wrapper">
              {!isEmpty(state.dppCollections) ? (
                state.dppCollections.DPPList.length ? (
                  state.dppCollections.DPPList.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_dpp_wrapper"
                        onClick={() => handleStoreDataInStorage("dpp", element)}
                      >
                        <div className="serial_number_wrapper">
                          <div className="image_wrapper">
                            <img src={images.user_activity_13} />
                          </div>
                        </div>
                        <div className="dpp_description_wrapper">
                          <div className="dpp_details_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.excersie_type}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">
                                {`${element.total_question} Questions`}
                              </p>
                            </div>
                          </div>
                          <div className="caret_wraper">
                            <div className="image_wrapper">
                              {/* {element.IsSaved ? (
                                  <img src={images.user_activity_4} />
                                ) : (
                                  <img src={images.user_activity_3} />
                                )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <img src={images.no_data} />
                  </div>
                )
              ) : (
                <div
                  className="no_data_found_wrapper"
                  style={{ textAlign: "center" }}
                >
                  <img src={images.no_data} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderRelatedVideos = () => {
    return (
      <Fragment>
        <div className="related_video_content_wrapper">
          {!isEmpty(state.videoDetails) ? (
            state.videoDetails.RecommendedVideos.data.length ? (
              <Fragment>
                <div className="text_content_wrapper">
                  <p className="text_content">Related Videos</p>
                </div>
                <div className="video_collection_wrapper">
                  <div className="video_collection">
                    {!isEmpty(state.videoDetails)
                      ? state.videoDetails.RecommendedVideos.data.length
                        ? state.videoDetails.RecommendedVideos.data.map(
                            (element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_video_wrapper"
                                >
                                  <div className="left_side_content_wrapper">
                                    <div className="image_wrapper">
                                      <img src={element.thumnail} />
                                    </div>
                                  </div>
                                  <div className="right_side_content_wrapper">
                                    <div className="text_content_wrapper_1">
                                      <p className="text_content_1">
                                        {element.video_title}
                                      </p>
                                    </div>
                                    <div className="sub_content_wrapper">
                                      <div className="left_side_content_wrapper">
                                        <div className="label_content_wrapper">
                                          <label>
                                            <span>
                                              <img
                                                src={images.user_activity_2}
                                              />
                                            </span>
                                            <span>{element.video_rating}</span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="right_side_content_wrapper">
                                        <div className="image_wrapper">
                                          {element.IsSaved ? (
                                            <img src={images.user_activity_4} />
                                          ) : (
                                            <img src={images.user_activity_3} />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </div>
                </div>
              </Fragment>
            ) : null
          ) : null}
        </div>
      </Fragment>
    );
  };

  const handleStoreDataInStorage = (type, data) => {
    switch (type) {
      case "notes":
        data.redirectedFrom = "activity";
        setNotesData(data);
        history.push("/notes");
        break;
      case "dpp":
        data.redirectedFrom = "activity";
        data.videoId = state.videoId;
        data.exerciseType = "DPP";
        // setExerciseResultData(data);
        // history.push("/evaluation_result");
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          state: { type: "dpp" },
        });

        // if (data.is_attempt) {
        //   setExerciseResultData(data);
        //   history.push("/evaluation_result");
        // } else {
        //   setExerciseData(data);
        //   history.push("/evaluation");
        // }
        break;
      default:
        break;
    }
  };

  // function timeStringToSeconds(timeString) {
  //   const [hours, minutes, seconds] = timeString.split(":").map(Number);
  //   return hours * 3600 + minutes * 60 + seconds;
  // }

  // const postWatchedVideoDetails = async () => {
  //   const { video_id, subject_id, video_duration } = watchedVideoData;

  //   console.log("watchedVideoDuration---------->", videoLastPaused);

  //   const payloadData = {
  //     video_id,
  //     subject_id,
  //     total_duration: timeStringToSeconds(video_duration),
  //     watched_time: Math.round(totalWatchTime),
  //     last_paused: Math.round(videoLastPaused),
  //   };

  //   const response = await axios.post(url_226, payloadData);
  // };

  const handleRedirect = () => {
    history.goBack();
    // postWatchedVideoDetails();
  };

  const getDppList = async () => {
    let videoId = state.videoId;
    if (videoId) {
      let data = {
        video_id: videoId,
        course_id: userPreference.courseDetails[0].course_id,
        type: "DPP",
        topic_id: topicData.topicId,
      };
      startLoading();
      try {
        const response = await axios.post(url_33, data);
        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            dppCollections: response.data.data,
          }));
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  return (
    <Fragment>
      <div className="videoFeatureContentComponent_wrapper">
        <div className="container">
          <div className="videoFeatureContentComponent_inner_wrapper">
            <div className="video_wrapper">
              <div className="video_inner_wrapper">
                <div className="sec_1_wrapper">
                  <div className="sec_1_inner_wrapper">
                    <div className="btn_wrapper">
                      <button onClick={() => handleRedirect()}>
                        <img src={images.user_activity_1} />
                      </button>
                    </div>
                    <div className="text_content_wrapper">
                      <p className="text_content">{videoData.video_title}</p>
                    </div>
                  </div>
                </div>
                <div className="video_frame_wrapper">
                  {!isEmpty(videoData) ? renderFrame(videoData) : null}
                </div>
                <div className="video_details_wrapper">
                  {renderVideoDetails()}
                </div>
                <div className="other_related_content_wrapper">
                  {renderOtherRelatedContent()}
                </div>
                <div className="related_videos_wrapper">
                  {renderRelatedVideos()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log("state for video pausedd", state);
  return {
    videoComponentTime: state.userBookmarkData.videoComponentTime,
    videoCurrentTime: state.userBookmarkData.videoCurrentTime,
    videoDetailsForTime: state.userBookmarkData.videoDetailsForTime,
    topicData: state.userActivity.topicData,
    subjectData: state.userActivity.subjectData,
    watchedVideoData: state.WatchedVideoDetailsSlice.watchedVideoDetails,
    videoLastPaused: state.WatchedVideoDetailsSlice.lastPaused,
    totalWatchTime: state.WatchedVideoDetailsSlice.totalWatchTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
    setExerciseResultData: (payload) => {
      dispatch(userActivityActions.setExerciseResultData(payload));
    },
    setVideoComponentTime: (payload) => {
      dispatch(userBookmarkActions.setVideoComponentTime(payload));
    },
    setVideoDetailsForTime: (payload) => {
      dispatch(userBookmarkActions.setVideoDetailsForTime(payload));
    },
    setVideoCurrentTime: (payload) => {
      dispatch(userBookmarkActions.setVideoCurrentTime(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoFeatureContentComponent);
